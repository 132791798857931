// extracted by mini-css-extract-plugin
export var carouselCaptionVertcenter = "index-module--carousel-caption-vertcenter--b16fR";
export var carouselCaptionWithindiv = "index-module--carousel-caption-withindiv--tHSG9";
export var carouselWithindiv = "index-module--carousel-withindiv--vWDvv";
export var logoText = "index-module--logo-text--buWSy";
export var carouselBgImage = "index-module--carousel-bg-image--Lmt0Q";
export var landingIcon = "index-module--landing-icon--wRvuT";
export var squareBlockContainer = "index-module--square-block-container--6kcn4";
export var squareBlockImage = "index-module--square-block-image--eaQyU";
export var squareBlockChildren = "index-module--square-block-children--eXeO5";
export var textBlockBg = "index-module--text-block-bg--7qZHh";
export var textBlockChildren = "index-module--text-block-children--zcqs7";
export var textBlockContainer = "index-module--text-block-container--klvsK";
export var textBlockMarkdown = "index-module--text-block-markdown--klrh1";
export var welcomeText = "index-module--welcome-text--1GdNe";