import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { Container, Carousel, Row, Col } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { Helmet } from 'react-helmet'

import ReactMarkdown from 'react-markdown'

import MainLayout from '../components/main-layout'
import Seo from '../components/seo'

import { animate } from '../styles/common.module.css'
import {
    carouselCaptionVertcenter,
    carouselCaptionWithindiv,
    carouselWithindiv,
    carouselBgImage,
    logoText,
    landingIcon,
    squareBlockContainer,
    squareBlockImage,
    squareBlockChildren,
    textBlockContainer,
    textBlockBg,
    textBlockChildren,
    welcomeText,
    textBlockMarkdown
} from "./index.module.css"


const IndexPage = ({ data }) => {

    return (
        <MainLayout pageTitle="Home" transparentNavbar={false}>

            <Seo title="Home" description="The pursuit of the best input." />

            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400&display=swap" rel="stylesheet" />
            </Helmet>
            <div className={carouselCaptionVertcenter}>
                <Carousel fade indicators={false} touch={false} pause={false} keyboard={false} controls={false} className={`w-100 ${carouselWithindiv}`}> {/* interval={500000} */}
                    {
                        data.strapiHomepage.carousel.map(imageFile => (

                            <Carousel.Item key={imageFile.id}>
                                <BgImage image={imageFile.localFile.childImageSharp.gatsbyImageData} className={`w-100 ${carouselBgImage}`} />
                            </Carousel.Item>

                        ))
                    }

                </Carousel>
                <div className={carouselCaptionWithindiv}>
                        <GatsbyImage image={data.strapiHomepage.logo.localFile.childImageSharp.gatsbyImageData} alt="" className={landingIcon} />
                        <h3 className={logoText}>ai03 Design Studio</h3>
                        <p className="mb-0 text-white">The pursuit of the best input.</p>
                </div>
            </div>


            <Container>

                <ReactMarkdown children={data.strapiHomepage.welcometext} className={`${welcomeText} text-center`} />

                <Row className="text-center ps-2 pe-2 justify-content-center">
                    {data.strapiHomepage.HomeGreetingBlocks.map(block => (
                        <Col lg={4} sm={6} xs={6} key={block.id} className={`pb-4 ${animate}`}>
                            {block.linkexternal
                                ?
                                <a href={block.link} role="button">
                                    <div className={`${squareBlockContainer} rounded-1`}>
                                        <BgImage image={block.background.localFile.childImageSharp.gatsbyImageData} className={squareBlockImage}>
                                        </BgImage>
                                        <div className={`text-white ${squareBlockChildren}`}>
                                            <h3>{block.text}</h3>
                                        </div>
                                    </div>
                                </a>
                                :
                                <Link to={block.link}>
                                    <div className={`${squareBlockContainer} rounded-1`}>
                                        <BgImage image={block.background.localFile.childImageSharp.gatsbyImageData} className={squareBlockImage}>
                                        </BgImage>
                                        <div className={`text-white ${squareBlockChildren}`}>
                                            <h3>{block.text}</h3>
                                        </div>
                                    </div>
                                </Link>
                            }
                        </Col>
                    ))}
                </Row>

            </Container>


            <div className={textBlockContainer}>
                <BgImage image={data.strapiHomepage.textblock.background.localFile.childImageSharp.gatsbyImageData} className={textBlockBg}>
                </BgImage>
                <Container className={`${textBlockChildren}`}>
                    <div className={`text-white text-center`}>

                        <ReactMarkdown children={data.strapiHomepage.textblock.text} className={textBlockMarkdown} />
                    </div>
                </Container>
            </div>

        </MainLayout>
    )
}

export const query = graphql`
query {
  strapiHomepage {
    carousel {
      id
      localFile {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 1.618
            transformOptions: { fit: COVER, cropFocus: CENTER }
            placeholder: BLURRED
            quality: 98
          )
        }
      }
    }
    logo {
      localFile {
        childImageSharp {
          gatsbyImageData(
            transformOptions: {fit: COVER, cropFocus: CENTER}
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    HomeGreetingBlocks {
      id
      text
      link
      linkexternal
      background {
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              transformOptions: { fit: COVER, cropFocus: CENTER }
              placeholder: BLURRED
              quality: 98
              width: 500
            )
          }
        }
      }
    }
    textblock {
        background {
          localFile {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER, cropFocus: CENTER}
                placeholder: BLURRED
                quality: 95
              )
            }
          }
        }
        text
    }
    welcometext
  }
}

`

export default IndexPage